import { useAppContext } from '../lib/stores/AppContext';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useState, useEffect } from 'react';
import PlayAudio from '../components/PlayAudio';
import Button from '../components/Button';
import Loading from '../components/Loading';
import { motion } from 'framer-motion';

interface YourItemsProps {
    yourItems: any[];
    setActiveTab: (tab: string) => void;
    learningSong: boolean;
    setLearningSong: (isLearning: boolean) => void
}
const YourItems: React.FC<YourItemsProps> = ({ yourItems, setActiveTab, learningSong, setLearningSong }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [songAudioFile, setSongAudioFile] = useState('');
    const [songLyricsFile, setSongLyricsFile] = useState('');
    const [songLearningType, setLearningType] = useState('solo');
    const [fullSong, setFullSong] = useState(false);
    const [metaData, setMetaData] = useState({});

    const { translations } = useAppContext();

    function handleLearnSong(audioFile, lyricsFile, learningType, fullSong, metaData) {
        console.log('handleLearnSong');
        console.log(metaData);
        setSongAudioFile(audioFile);
        setSongLyricsFile(lyricsFile.trim());
        setLearningType(learningType);
        setLearningSong(true);
        setFullSong(fullSong);
        setMetaData(metaData);
    }

    function handleOnFinishLearning() {
        setLearningSong(false);
    }

    let ui;

    useEffect(() => {
        // Set visibility to true after component mounts
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100); // Delay to allow for CSS transition

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, []);

    if (learningSong) {
        ui = (
            <PlayAudio
                audioFile={songAudioFile}
                onFinishLearning={handleOnFinishLearning}
                lyricsFile={songLyricsFile}
                songLearningType={songLearningType}
                fullSong={fullSong}
                metaData={metaData}
            />
        );
    } else {


        console.log('yourItems');
        console.log(yourItems);
        
        ui = (
            <motion.div className="tab-pane active" style={{ margin: '0 auto' }}
                variants={{
                    hidden: { opacity: 0, scale: 0.5 },
                    visible: { opacity: 1, scale: 1 }
                }}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                transition={{
                    type: 'spring',
                    duration: 1,
                    ease: 'easeInOut'
                }}
            >
                {yourItems.length > 0 ? (
                    yourItems.map((item) => (
                        <div key={item.item_id}>
                            <br />
                            <h2>{item.song_name}</h2>
                            <motion.table
                                className="bottomBorder tableHover"
                                variants={{
                                    visible: { transition: { staggerChildren: 0.5 } }
                                }}
                            >
                                <tbody>
                                    <motion.tr className="topBorder" variants={{
                                        visible: { opacity: 1, y: 0 },
                                        hidden: { opacity: 0, y: 10 }
                                    }}>
                                        <td>
                                            <img src={item.image} height="50" width="50" alt={item.song_name} />
                                        </td>
                                        <td style={{ width: 100, height: 100 }}>
                                            <CircularProgressbar
                                                value={item.song_score}
                                                text={`${item.song_score}%`}
                                                maxValue={100}
                                                styles={buildStyles({ pathColor: '#4caf50' })}
                                            />
                                        </td>
                                        <td>
                                            <Button additionalClassNames="center_valign" style={{ width: '30%' }}
                                                onClick={() => handleLearnSong(item.song_audio_file, item.song_lyrics_file, "singalong", true, item)}>
                                                <>
                                                    {translations.launch_song_along}
                                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                                </>
                                            </Button>
                                            <Button additionalClassNames="center_valign" style={{ width: '30%' }}
                                                onClick={() => handleLearnSong(item.song_audio_file, item.song_lyrics_file, "solo", true, item)}>
                                                <>
                                                    {translations.launch_song_solo}
                                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                                </>
                                            </Button>
                                        </td>
                                    </motion.tr>
                                    {
                                        item.phrases.map((phrase, index) => (
                                            
                                            <motion.tr key={index} variants={{
                                                visible: { opacity: 1, y: 0 },
                                                hidden: { opacity: 0, y: 10 }
                                            }}>
                                                <td>Phrase #{index + 1}</td>
                                                <td>
                                                    <CircularProgressbar
                                                        value={item.scores[index]}
                                                        maxValue={100}
                                                        text={`${item.scores[index]}%`}
                                                        styles={buildStyles({ pathColor: '#4caf50' })}
                                                    />
                                                </td>
                                                <td>
                                                    <Button additionalClassNames="center_valign btn_alt" style={{ width: '30%' }}
                                                        onClick={() => handleLearnSong(item.audio_file[index], item.lyrics_file[index], "singalong", false, { "abs_melody" : item.abs_melody[index], "durations" : item.durations[index] } )}>
                                                        <>
                                                            {translations.launch_song_along}
                                                            <i className="fa fa-circle-o-notch fa-spin"></i>
                                                        </>
                                                    </Button>
                                                    <Button additionalClassNames="center_valign btn_alt" style={{ width: '30%' }}
                                                        onClick={() => handleLearnSong(item.audio_file[index], item.lyrics_file[index], "solo", false, { "abs_melody" : item.abs_melody[index], "durations" : item.durations[index] } )}>
                                                        <>
                                                            {translations.launch_song_solo}
                                                            <i className="fa fa-circle-o-notch fa-spin"></i>
                                                        </>
                                                    </Button>
                                                </td>
                                            </motion.tr>
                                        ))}
                                </tbody>
                            </motion.table>
                        </div>
                    ))
                ) : (
                    <Loading text="Getting your songs..." />
                )
                }
            </motion.div >
        );
    }

    return ui;
}

export default YourItems;
