import React, { useState, useEffect } from 'react';
import { Chart, ChartConfiguration } from 'chart.js/auto';
import { useAppContext } from '../lib/stores/AppContext';
import Loading from '../components/Loading';

interface ChartComponentProps {
  chartData: any;
  hasChartData: boolean;
  chartDataLoaded: boolean
}

const ChartComponent: React.FC<ChartComponentProps> = ({ chartData, hasChartData = false, chartDataLoaded = false }) => {
  const { translations } = useAppContext();
  const [userStats, setUserStats] = useState('');
  const [currentView, setCurrentView] = useState('overall');
  const [rhythmicChart, setRhythmicChart] = useState<Chart | null>(null);
  const [reviewChart, setReviewChart] = useState<Chart | null>(null);

  useEffect(() => {
    const init = () => {
      if (chartData?.review_melodies?.length > 0 || chartData?.session_scores_rhythmic?.length > 0) {
        setUserStats(getStats(chartData.user_stats, currentView));
        populateCharts();
        populateDropdown(chartData.review_melodies);
      }
    };

    init(); // Call init when component mounts or when chartData changes
  }, [chartData]); // Add dependencies as needed

  const getStats = (userStatsData: any[], period: string) => {
    const now = new Date();
    let startDate: Date;
    let translationKey: string;
    let totalMinutesSpent = 0;
    let totalPracticeSessions = 0;

    switch (period) {
      case 'last_week':
        startDate = new Date(now.setDate(now.getDate() - 7));
        translationKey = 'last_week_you_practiced';
        break;
      case 'last_month':
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        translationKey = 'last_month_you_practiced';
        break;
      default:
        startDate = new Date(0);
        translationKey = 'overall_you_practiced';
    }

    userStatsData.forEach((stat) => {
      const statDate = new Date(stat.Date);
      if (statDate >= startDate) {
        totalMinutesSpent += stat.minutes_spent;
        totalPracticeSessions += stat.no_practice_sessions;
      }
    });

    const totalTimeSpent =
      totalMinutesSpent >= 60
        ? `${Math.round(totalMinutesSpent / 60)} ${translations.hours}`
        : `${totalMinutesSpent.toFixed(0)} ${translations.minutes}`;

    return translations[translationKey]
      .replace('{totalTimeSpent}', totalTimeSpent)
      .replace('{totalPracticeSessions}', totalPracticeSessions.toString());
  };

  const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentView(event.target.value);
    if (chartData) setUserStats(getStats(chartData.user_stats, event.target.value));
  };

  const createConfig = (data: any): ChartConfiguration => ({
    type: 'line',
    data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
      },
      scales: {
        y: {
          min: 0,
          max: 10,
        },
      },
    },
  });


  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
 

  const populateDropdown = (melodyReviewData: any[]) => {
    const dropdown = document.getElementById('melodyReviewDropdown') as HTMLSelectElement;
    dropdown.innerHTML = '';
    melodyReviewData.forEach((melody) => {
        const optionElement = document.createElement('option');
        optionElement.value = melody.phrase_name;
        optionElement.textContent = melody.phrase_name;
        dropdown.appendChild(optionElement);
    });

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Select a phrase';
    defaultOption.selected = true; 
    defaultOption.disabled = true; 

    dropdown.appendChild(defaultOption);

    if (melodyReviewData.length > 0) {
        dropdown.value = melodyReviewData[0].phrase_name;
        const event = new Event('change');
        dropdown.dispatchEvent(event);
    }

    dropdown.value = ''
};


  const filterArrayOfArrays = (array: any[], value: string) =>
    array.filter((item) => item.phrase_name === value);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (!selectedValue || !chartData) return;

    if (reviewChart) reviewChart.destroy();

    const filteredData = filterArrayOfArrays(chartData.review_melodies, selectedValue);
    const dates = filteredData.map((el) => el.Date);
    const scores = filteredData.map((el) => el.score * 10);

    const data = {
      labels: dates,
      datasets: [
        {
          label: translations.melody_review,
          backgroundColor: 'rgb(200, 150, 255)',
          borderColor: 'rgb(200, 150, 255)',
          data: scores,
        },
      ],
    };

    const ctx = document.getElementById('reviewMelodiesChart') as HTMLCanvasElement;
    setReviewChart(new Chart(ctx, createConfig(data)));
  };


  const populateCharts = () => {
    if (!chartData || !chartData.session_scores_rhythmic?.length) return;

    const dates = chartData.session_scores_rhythmic.map((el: any) => el.Date);
    const scores = chartData.session_scores_rhythmic.map((el: any) => el.score * 10);

    const data = {
      labels: dates,
      datasets: [
        {
          label: translations.my_sing_training_button,
          backgroundColor: 'rgb(75, 192, 192)',
          borderColor: 'rgb(75, 192, 192)',
          data: scores,
        },
      ],
    };

    const ctx = document.getElementById('rhythmicChart') as HTMLCanvasElement;
    setRhythmicChart(new Chart(ctx, createConfig(data)));
  };


  return (
    <>
      <div>
        { hasChartData && chartDataLoaded ? (
          <div id="myProgress">
            <br />
            <div className="view-select-wrapper">
              <p style={{ margin: 0 }}>{userStats}</p>
              <div className="select-wrapper">
                <select onChange={handleViewChange} defaultValue="overall">
                  <option value="overall">{translations["overall"]}</option>
                  <option value="last_month">{translations["last_month"]}</option>
                  <option value="last_week">{translations["last_week"]}</option>
                </select>
              </div>
            </div>

            <div id="rhythmicChartWrapper" style={{
              display: chartData?.session_scores_rhythmic?.length > 0
                ? 'block'
                : 'none'
            }} >
              <hr />
              <h3 style={{ color: '#229787' }}>{translations["my_sing_training_button"]}</h3>
              <hr />
            </div>
            <canvas id="rhythmicChart"></canvas>
            <hr />

            <div id="reviewMelodiesChartWrapper" style={{
              display: chartData?.review_melodies?.length > 0
                ? 'block'
                : 'none'
            }} >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 style={{ margin: 0, textAlign: 'center', color: '#229787' }}>
                  {translations["my_singpause_songs_button"]}
                </h3>
                <div className="select-wrapper">
                  <select id="melodyReviewDropdown" onChange={handleDropdownChange}> 

                  </select>
                </div>
              </div>
              <hr />
              <canvas id="reviewMelodiesChart"></canvas>
              <hr />
            </div>
          </div>
        )  : chartDataLoaded && !hasChartData ? (
          // Conditional rendering for missing data or loading state
          <p>{translations["missing_data"]}</p> 
        ) : (<Loading text = "Fetching your progress." />)}
      </div>
    </>
  );
};

export default ChartComponent;