import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import birdIcon from '../lib/assets/img/bird.png';

interface BirdAnimationProps {
    style?: React.CSSProperties;
}

const BirdAnimation: React.FC<BirdAnimationProps> = ({ style = { height: "150px", width: "150px" } }) => {
    const [animationStage, setAnimationStage] = useState(0);
    const [animationRange, setAnimationRange] = useState([-10, 10]);

    const MAX_RANGE = 30; // Set the maximum limit for expansion

    // Update the animation stage every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationStage((prev) => prev + 1);
        }, 2000); // Match this to the duration of the animation

        return () => clearInterval(interval);
    }, []);

    // Expand the animation range within limits
    useEffect(() => {
        if (animationStage > 0 && animationRange[1] < MAX_RANGE) { // Check to keep expanding up to the limit
            setAnimationRange((prev) => [
                Math.max(prev[0] - 10, -MAX_RANGE), 
                Math.min(prev[1] + 10, MAX_RANGE)
            ]);
        }
    }, [animationStage, animationRange]);

    const birdVariants = {
        initial: { x: 0 },
        animate: {
            x: [0, animationRange[1], 0, animationRange[0], 0],
            transition: {
                duration: 2,
                ease: 'easeInOut',
                repeat: Infinity
            }
        }
    };

    return (
        <motion.div
            variants={birdVariants}
            initial="initial"
            animate="animate"
        >
            <img src={birdIcon} alt="Bird" style={style} />
        </motion.div>
    );
};

export default BirdAnimation;
