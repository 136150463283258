import { motion } from 'framer-motion';

interface ButtonProps {
    children: any;
    onClick?: (arg) => void
    altColor?: boolean;
    id?: string;
    additionalClassNames?: string;
    style?: any
}

const Button: React.FC<ButtonProps> = ({ children, onClick,
    altColor = false, id = "",
    additionalClassNames = "",
    style = {} }) => {

    return (
        <motion.button
            id={id}
            onClick={onClick}
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 500 }}
            className={altColor ? "btn btn_alt " + additionalClassNames : "btn " + additionalClassNames}
            style={style}
        >{children}
        </motion.button>
    )

}

export default Button;