import React, { useRef, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Modal from './Modal';
import Button from '../components/Button';

const timeout = 3 * 60_000; // 3 minutes
const promptBeforeIdle = 60_000; // 1 minute

const AutoLogout: React.FC<{ logoutUser: () => void }> = ({ logoutUser }) => {
    const [showWarning, setShowWarning] = useState(false);
    const [remaining, setRemaining] = useState<number>(timeout);
    const stayLoggedInButtonRef = useRef<HTMLButtonElement>(null);

    const handleOnIdle = () => {
        logoutUser();
    };

    const triggerClick = () => {
        const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        window.dispatchEvent(event);
    };

    const handleOnPrompt = () => {
        triggerClick()
        setShowWarning(true);
    };

    const handleStayLoggedIn = () => {
        reset();
        setShowWarning(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        if (showWarning) {
            stayLoggedInButtonRef.current?.focus();
        }

        return () => clearInterval(interval);
    });

    const { getRemainingTime, activate, reset } = useIdleTimer({
        timeout,
        promptBeforeIdle,
        onPrompt: handleOnPrompt,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    return (
        <Modal onClose={() => setShowWarning(false)} showModal={showWarning} style={{ zIndex: 9999 }}>
            {showWarning && (
                <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>Are you still here?</h3>
                    <p>You will be logged out in <strong>{remaining}</strong> seconds.</p>
                    <Button
                        onClick={handleStayLoggedIn}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '16px'
                        }}
                    >
                        I'm still here
                    </Button>
                </div>
                </>
            )}
        </Modal>
    );

};

export default AutoLogout;