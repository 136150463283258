import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppProvider, useAppContext } from './lib/stores/AppContext';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Teacher from './components/Teacher';

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAppContext();

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/teacher" element={<Teacher />} />

    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};

export default App;
