
import { motion, AnimatePresence } from 'framer-motion';

const Modal: React.FC<{
    children: any
    onClose: () => void;
    showModal: boolean;
    style?: any
}> = ({ children, onClose, showModal = false, style = {} }) => {

    return (
        <AnimatePresence>
            {showModal && (
                <motion.div
                    key="modal"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 30 }}
                    className="modal-background"
                    onClick={onClose}
                    style={style}
                >
                    <div className="modal-content alignCenter" onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Modal;