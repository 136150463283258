import Timeline from '../components/Timeline';
import trialBlock from './trialBlock';
import { useState } from 'react';
import React from 'react';
import Loading from '../components/Loading';

const MySingTraining: React.FC<{
    items: any;
    gotItems: boolean;
    setActiveTab?: (tab) => void;
    setGotSingTrainingItems: (hasGot) => void;
}> = ({ items, gotItems = false, setActiveTab, setGotSingTrainingItems }) => {

    console.log('gotItems', gotItems);

    const [pageNumber, updatePageNumber] = useState(1);

    function incrementPage() {
        updatePageNumber((pageNumber: number) => pageNumber + 1);
    }

    const trials = gotItems ? trialBlock(items, incrementPage, false, setGotSingTrainingItems, setActiveTab) : false;

    return (
        gotItems ? (<Timeline pageNumber={pageNumber} timeline={trials} />) :
            (<Loading text="Finding you something to learn..." />)
    );


}

export default MySingTraining;