class TrialData {
    stimuli: string = "";
    stimuli_durations: string = "";
    trial_time_started: number = new Date().getTime();;
    trial_time_completed: number = new Date().getTime();;
    instrument: string = "Voice";
    attempt: number = 1;
    item_id: string = "";
    display_modality: string = "audio";
    phase: string = "test";
    rhythmic: boolean = true;
    session_id: number = 1;
    test_id: number = 1;
    onset: boolean = false;
    new_items_id: number = 1;
    review_items_id: number = 1;
    user_id: number = 1;
    feedback: boolean = true;
    feedback_type: string = "opti3";
    trial_paradigm: string = "call_and_response";
    additional: any = "";

    constructor(initValues: Partial<TrialData> = {}) {
        
        // Assign initial values
        Object.assign(this, initValues);

        // Check if stimuli and stimuli_durations have the same length
        if (this.stimuli.split(",").length !== this.stimuli_durations.split(",").length) {
            throw new Error("stimuli and stimuli_durations must have the same length.");
        }
    }
}

export default TrialData;