
import Modal from '../components/Modal';
import Button from '../components/Button';

interface MicModalProps {
    showModal: boolean;
    onClose: () => void
}

const MicModal: React.FC<MicModalProps> = ({ showModal, onClose }) => {

    const ui = showModal ? (
        <Modal onClose={onClose} showModal={showModal}>
            <h2> Microphone Test</h2 >
            <p>Make sure your microphone is plugged in and allow the browser to access your microphone.</p>
            <meter id="volumeMeter" high={0.25} max="1" value="0" style={{ width: '400px', height: '50px', margin: '20px 0' }}></meter>
            <p>You should see feedback on the monitor above.</p>
            <Button onClick={onClose}>I can see feedback</Button>
        </Modal>
    )
        : null;

    return (ui);
}

export default MicModal;