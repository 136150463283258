// Header.tsx
import React from 'react';
import songbirdLogo from '../lib/assets/img/songbird_logo.png';
import { motion } from 'framer-motion';

const SONGBIRD_APP_URL = process.env.REACT_APP_SONGBIRD_URL

const Header: React.FC = () => {
  return (
    <header>
      <a href={SONGBIRD_APP_URL}>
        <motion.div
          id="header_title"
          initial={{ x: 0, opacity: 1, rotate: 0 }} // Initial position of the logo
          whileHover={{
            x: [0, 50, 200, 1200], // Moves to the right with multiple points for a smooth flying effect
            opacity: [1, 0.8, 0.5, 0], // Gradually fades out
            rotate: [0, 15, -10, 20], // Adds a bit of rotation to mimic a 'flying' movement
            transition: {
              duration: 2, // Total duration of the animation
              ease: 'easeInOut', // Smooth easing for a natural effect
            },
          }}
          transition={{ type: 'spring', stiffness: 500 }}
        >
          <img src={songbirdLogo} alt="Songbird Logo" />
        </motion.div>
      </a>
    </header>
  );
};

export default Header;
