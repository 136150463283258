// @ts-nocheck
import { SampleLibrary } from './assets/js/Tonejs-Instruments';
import * as Tone from 'tone';

console.log("loaded musicassessr.js");

const apiUrl = process.env.REACT_APP_API_BASE_URL;
let startTime;
let rec;
let stimulus_trigger_times = [];
let AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext;
let gumStream;
let input;
let pattern; // The melodic pattern being played. We only want one to be played at once.

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const initSynth = () => {

  window.synthParameters = {
    oscillator: {
      type: 'sine',
      partialCount: 4
    },
    envelope: {
      attack: 0.01,
      decay: 0.01,
      sustain: 0.50,
      release: 0.01,
      attackCurve: 'cosine'
    }
  };


  window.synth = new Tone.Synth(synthParameters).toDestination();

}

export const initPiano = () => {
  window.piano = SampleLibrary.load({
    instruments: "piano",
    minify: true
  });

  window.piano.toDestination();

}

export const initVoiceDoo = () => {

  window.voice_doo = SampleLibrary.load({
    instruments: "voice_doo",
    minify: true
  });

  window.voice_doo.toMaster();

}

export const initVoiceDaa = () => {

  window.voice_daa = SampleLibrary.load({
    instruments: "voice_daa",
    minify: true
  });

  window.voice_daa.toMaster();

}


export const toneJSInit = () => {

  console.log("toneJS Inited!");

  initPiano();

  initSynth();

}

function connect_sound(sound) {

  if (sound === "tone") {
    window.piano.disconnect();

    window.synth = new Tone.Synth(synthParameters).toDestination();

  } else if (sound === "voice_doo") {

    window.piano.disconnect();
    window.synth.disconnect();

  } else if (sound === "voice_daa") {

    window.piano.disconnect();
    window.synth.disconnect();

  } else if (sound === "rhythm") {
    window.piano.disconnect();
    window.synth.disconnect();
  }
  else {
    window.synth.disconnect();

    window.piano.toDestination();
  }
}




function triggerNote(sound, freq_tone, seconds, time) {

  let triggerTime = new Date().getTime();
  stimulus_trigger_times.push(triggerTime);

  if (sound === "piano") {
    piano.triggerAttackRelease(freq_tone, seconds, time);
  } else if (sound === "voice_doo") {
    voice_doo.triggerAttackRelease(freq_tone, seconds, time);
  } else if (sound === "voice_daa") {
    voice_daa.triggerAttackRelease(freq_tone, seconds, time);
  } else if (sound === "rhythm") {
    rhythm.triggerAttackRelease(freq_tone, seconds, time);
  } else {
    synth.triggerAttackRelease(freq_tone, seconds, time);
  }

}

export const playSingleNote = (note_list, dur_list, sound, trigger_end_of_stimuli_fun = null) => {
  let freq_list = Tone.Frequency(note_list, "midi").toNote();
  triggerNote(sound, freq_list, dur_list);

  setTimeout(() => {
    if (trigger_end_of_stimuli_fun !== null) {
      trigger_end_of_stimuli_fun();
    }
  }, dur_list * 1000);

}

export const playSeq = async (note_list: any, dur_list: any = null, sound = 'piano',
  trigger_start_of_stimuli_fun = null, trigger_end_of_stimuli_fun = null) => {

  stimulus_trigger_times = [];

  Tone.Transport.stop();

  if (pattern) {
    pattern.dispose();
  }

  connect_sound(sound);


  if (trigger_start_of_stimuli_fun !== null) {
    trigger_start_of_stimuli_fun();
  }

  if (typeof note_list === 'number') {
    playSingleNote(note_list, dur_list, sound, trigger_end_of_stimuli_fun);
  } else {

    let freq_list = note_list.map(x => Tone.Frequency(x, "midi").toNote());

    let last_note = freq_list.length;
    let count = 0;

    let notesAndDurations = bind_notes_and_durations(freq_list, dur_list);
    notesAndDurations = notesAndDurations.map(timeFromDurations);

    pattern = new Tone.Part((time, value) => {

      triggerNote(sound, value.note, 0.50);
      count = count + 1;

      if (count === last_note) {
        stopSeq(pattern);
        if (trigger_end_of_stimuli_fun !== null) {
          trigger_end_of_stimuli_fun();
        }

      }
    }, notesAndDurations);
    pattern.start(0).loop = false;
    Tone.Transport.start();
  }


}

export const stopSeq = (pattern) => {

  pattern.stop();
  Tone.Transport.stop();
  startRecording();
  document.getElementById('rec').style.display = 'block';

}


function timeFromDurations(value, i, arr) {
  const prevTime = arr[i - 1]?.time;
  value.time = prevTime + arr[i - 1]?.duration || 0;
  return value;
}

function bind_notes_and_durations(notes, durations) {
  let i;
  let currentNote;
  let currentDur;
  let result = [];

  for (i = 0; i < notes?.length; i++) {
    currentNote = notes[i];
    currentDur = durations[i];
    result[i] = { duration: currentDur, note: currentNote };
  }
  return (result);
}


export function frequencyToMidi(frequency: number) {
  if (frequency <= 0) {
    throw new Error("Frequency must be greater than 0");
  }
  const midiNote = Math.round(69 + 12 * Math.log2(frequency / 440));
  return midiNote;
}


export const startRecording = async (type = "audio") => {

  await delay(500);

  startTime = new Date().getTime();

  console.log('Start time : ');
  console.log(startTime);

  if (type === "audio") {
    startAudioRecording();
  }
  // Add MIDI support later;


}

export const startAudioRecording = () => {

  let constraints = { audio: true, video: false }

  navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {

    audioContext = new AudioContext();

    gumStream = stream;
    input = audioContext.createMediaStreamSource(stream);
    rec = new Recorder(input, { numChannels: 1 })

    rec.record();

    console.log("Recording started");

  }).catch(function (err) {

    console.log('error...');
    console.log(err);

  });
}


export const microTest = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  if (stream) {
    const audioContext = new AudioContext();
    const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Float32Array(analyserNode.fftSize);

    const onFrame = () => {
      analyserNode.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (let amplitude of pcmData) {
        sumSquares += amplitude * amplitude;
      }
      let val = Math.sqrt(sumSquares / pcmData.length);

      const volumeMeterEl = document.getElementById('volumeMeter');
      if (volumeMeterEl && volumeMeterEl.tagName === 'METER') {
        volumeMeterEl.value = val;
      }

      window.requestAnimationFrame(onFrame);
    };

    window.requestAnimationFrame(onFrame);
  }
};

function copyAndCoerceToString(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, String(value)])
  );
}


export const uploadRecord = async (metadata) => {
  rec.stop();
  gumStream.getAudioTracks()[0].stop();

  const exportedWavBlob = await new Promise((resolve) => {
    rec.exportWAV(resolve);
  });

  const currentTime = new Date().toISOString();
  const fileName = currentTime;

  const md = copyAndCoerceToString(metadata);


  const requestBody = {
    "filename": fileName,
    "metadata": md
  };

  try {
    const response = await fetch(apiUrl + "/v2/get-audio-presigned-url-legacy", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`Failed to get response: ${response.status}`);
    }

    const responseData = await response.json();

    const url = responseData.url;

    const uploadResponse = await fetch(url, {
      method: 'PUT',
      body: exportedWavBlob
    });

    if (!uploadResponse.ok) {
      throw new Error(`Failed: ${uploadResponse.status}`);
    }
    console.log('Successful');
  } catch (error) {
    console.error(error);
  }
  return fileName;
};


// Scoring

function getAllConsecutiveSubsets(arr, N) {

  const subsets = [];

  for (let i = 0; i <= arr.length - N; i++) {
    const subset = arr.slice(i, i + N);
    if (subset.length > 1) {
      subsets.push(subset.join(","));
    } else {
      subsets.push(subset);
    }
  }

  return subsets;
}

function getCounts(array) {
  const counts = array.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});
  return counts;
}

function countOccurrences(array, searchString) {
  return array.filter(item => item === searchString).length;
}

export function ngrukkon(x, y, N = 3): number {

  console.log('ngrukkon');
  console.log(x);
  console.log(y);

  const xNgrams = getAllConsecutiveSubsets(x, N);
  const yNgrams = getAllConsecutiveSubsets(y, N);

  const joint = [...new Set([...xNgrams, ...yNgrams])];

  // Init a count array with all 0's
  const initJointCount = getCounts(joint);
  for (const key in initJointCount) {
    if (initJointCount.hasOwnProperty(key)) {
      initJointCount[key] = 0; // Set each value to 0
    }
  }

  // Duplicate for each
  const xNgramCounts = { ...initJointCount };
  const yNgramCounts = { ...initJointCount };

  for (const idx in joint) {
    const ngram = joint[idx];
    if (xNgrams.includes(ngram)) {
      const nGramCount = countOccurrences(xNgrams, ngram)
      xNgramCounts[ngram] = nGramCount;
    }
  }

  // Do the same for y (factor this out?)
  for (const idx in joint) {
    const ngram = joint[idx];
    if (yNgrams.includes(ngram)) {
      const nGramCount = countOccurrences(yNgrams, ngram);
      yNgramCounts[ngram] = nGramCount;
    }
  }

  const xNgramCountValues = Object.values(xNgramCounts);
  const yNgramCountValues = Object.values(yNgramCounts);

  const lengthDiffs = xNgramCountValues.map((value, index) => Math.abs(value - yNgramCountValues[index]));

  const sum = lengthDiffs.reduce((acc, currentValue) => acc + currentValue, 0);

  return 1 - sum / (x.length + y.length)
}


function editDist(str1, str2) {
  const dp = Array.from({ length: str1.length + 1 }, () => Array(str2.length + 1).fill(0));

  // Initialize the first row and column
  for (let i = 0; i <= str1.length; i++) {
    dp[i][0] = i; // deletion from str1
  }
  for (let j = 0; j <= str2.length; j++) {
    dp[0][j] = j; // insertion to str1
  }

  // Fill in the rest of the dp table
  for (let i = 1; i <= str1.length; i++) {
    for (let j = 1; j <= str2.length; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;

      dp[i][j] = Math.min(
        dp[i - 1][j] + 1,    // deletion
        dp[i][j - 1] + 1,    // insertion
        dp[i - 1][j - 1] + cost // substitution
      );
    }
  }

  return dp[str1.length][str2.length];
}

function editSim(s, t) {
  return 1 - editDist(s, t) / Math.max(s.length, t.length)
}

function classifyDuration(durVec, refDuration = 0.5) {

  if (typeof durVec === 'number') {
    durVec = [durVec];
  }
  // Calculate relative durations
  const relDur = durVec.map(dur => dur / refDuration);

  // Initialize rhythm classes with -2
  const rhythmClass = Array(relDur.length).fill(-2);

  // Assign rhythm classes based on conditions
  for (let i = 0; i < relDur.length; i++) {
    if (relDur[i] > 3.3) {
      rhythmClass[i] = 2;
    } else if (relDur[i] > 1.8) {
      rhythmClass[i] = 1;
    } else if (relDur[i] > 0.9) {
      rhythmClass[i] = 0;
    } else if (relDur[i] > 0.45) {
      rhythmClass[i] = -1;
    }
  }

  return rhythmClass;
}

function intToUtf8(int) {
  let res;
  if (typeof (int) === 'number') {
    res = String.fromCodePoint(int);
  } else {
    res = int.map(code => String.fromCodePoint(code)).join('');
  }
  return res
}

export function rhythfuzz(durVec1, durVec2): number {


  console.log('rhythfuzz');
  console.log("stimulus: ", durVec1.map(num => parseFloat(num.toFixed(2))));
  console.log("recall: ", durVec2.map(num => parseFloat(num.toFixed(2))));

  const ioiClass1 = classifyDuration(durVec1);
  const ioiClass2 = classifyDuration(durVec2);


  const ioiClass1Offset = ioiClass1.map((el) => el + 32);
  const ioiClass2Offset = ioiClass2.map((el) => el + 32);

  const str1 = intToUtf8(ioiClass1Offset);
  const str2 = intToUtf8(ioiClass2Offset);

  return editSim(str1, str2)
}

// Transposing functions

function range(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

function transposeMelody(melodyArray, transposition) {
  return melodyArray.map((note) => note + transposition);
}

function transposeMelodyAcrossOctaves(melodyArray) {
  const transpositions = range(-24, 24);
  const result = transpositions.map((transposition) => transposeMelody(melodyArray, transposition));
  return result
}

function countNumbersInRange(rangeStart, rangeEnd, numbersArray) {
  return numbersArray.reduce((count, number) => {
    if (number >= rangeStart && number <= rangeEnd) {
      count += 1;
    }
    return count;
  }, 0);
}

function getObjectsWithMaxNotes(arr) {
  // Find the maximum `noNotesInRange` in the array
  const maxNotes = Math.max(...arr.map(obj => obj.noNotesInRange));
  // Filter the array to include only objects with `noNotesInRange` equal to `maxNotes`
  return arr.filter(obj => obj.noNotesInRange === maxNotes);
}

function getRandomInt(min, max) {
  min = Math.ceil(min);  // Round up `min` to ensure it's an integer
  max = Math.floor(max); // Round down `max` to ensure it's an integer
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getBestTranspositionForRange(melodyArray, bottomNote = 60, topNote = 72) {
  console.log('Transpose melody..!');
  const melodyTranspositions = transposeMelodyAcrossOctaves(melodyArray);
  const numberNotesInRange = melodyTranspositions.map((transposedMelody) => {
    return {
      transposedMelody: transposedMelody,
      noNotesInRange: countNumbersInRange(bottomNote, topNote, transposedMelody)
    }
  });
  const winners = getObjectsWithMaxNotes(numberNotesInRange);
  let winner;
  if(winners.length === 1) {
    winner = winners;
  } else {
    const ranInt = getRandomInt(0, (winners.length-1) );
    console.log('ranInt', ranInt);
    console.log('winners', winners);
    winner = winners[ranInt];
  }
  console.log('winner', winner);
  return winner.transposedMelody;
}







toneJSInit();