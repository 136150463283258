import React, { useEffect, useState } from 'react';
import { signIn, signUp } from "@aws-amplify/auth";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SignUpModal from './SignUpModal';
import songbirdIcon from '../lib/assets/img/songbird_icon.png';
import { useAppContext } from '../lib/stores/AppContext';
import "../lib/Cognito";
import Button from '../components/Button';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';


const Login: React.FC = () => {
  const { translations, showModal, setShowModal, isAuthenticated } = useAppContext();
  const [signUpData, setSignUpData] = useState<any>({});
  const [form, setForm] = useState<HTMLFormElement | null>(null);
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      toast.loading('', { duration: 1000 });
      await signIn({ username, password });
      localStorage.setItem('isSelectItems', 'true');
      navigate('/dashboard');
    } catch (error: any) {
      if (error.message.includes('The quota has been exceeded')) {
        localStorage.clear();
      } else if (error.message.includes('Incorrect username or password')) {
        toast.error(translations["signin_wrong_creds"]);
      }
      else {
        toast.error(error.message || 'Sign in failed');
      }
    }
  };

  const handleSignUpButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const signUpFormData = {
      username: data.get('username') as string,
      password: data.get('password') as string,
      email: data.get('email') as string,
      confirmPassword: data.get('confirm_password') as string,
    };
    setSignUpData(signUpFormData);
    setShowModal(true);
  };

  const handleSignUp = async () => {
    const { username, password, email, confirmPassword } = signUpData;
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
            'custom:groupId': '5',
          }
        }
      });
      form?.reset();
      toast.success(translations["singup_success"]);
      setShowModal(false);
    } catch (error: any) {
      if (error.message.includes('The quota has been exceeded')) {
        localStorage.clear();
      } else if (error.message.includes('already exists')) {
        toast.error('User already exists');
        form?.reset();
      } else if (error.message.includes('Invalid username format')) {
        toast.error('Invalid username format');
        form?.reset();
      } else {
        toast.error(error.message || 'Sign up failed');
      }
    }
  };

  return (
    <div>
      <Header />

      <div className="wrapper">
        <Toaster />
        <SignUpModal showModal={showModal} onConfirm={handleSignUp} onClose={() => setShowModal(false)} />

        <div id="dynamic_ui">
          <div id="prelogin">
            <p className="logged_out_message2" style={{ marginRight: '20px' }}>
              {translations.welcome2}
            </p>
          </div>
          <br />
          <form id="sign-sign_in_section" method="POST" onSubmit={handleSubmit}>
            <div className="content-title">{translations.sign_in}</div>
            <div className="form-group shiny-input-container">
              <label htmlFor="sign-sign_in_user" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_in_user"
                type="text"
                name="email"
                className="shiny-input-text form-control shiny-bound-input"
                placeholder={translations.username}
              />
            </div>
            <div className="form-group">
              <label htmlFor="sign-sign_in_password" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_in_password"
                name="password"
                type="password"
                className="shiny-input-text form-control shiny-bound-input"
                placeholder={translations.password}
              />
            </div>
            <Button>
              {translations.sign_in}
            </Button>
          </form>

          <div className="vr-separator">
            <div className="vr-line"></div>
            <img src={songbirdIcon} alt="Songbird Icon" />
            <div className="vr-line"></div>
          </div>

          <form
            id="sign_up_section"
            method="POST"
            ref={setForm}
            onSubmit={handleSignUpButtonClick}
          >
            <div className="content-title-alt">{translations.sign_up}</div>
            <div className="form-group">
              <label htmlFor="sign-sign_up_user" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_up_user"
                type="text"
                className="shiny-input-text form-control shiny-bound-input"
                name="username"
                placeholder={translations.username_sign_up}
              />
            </div>
            <div className="form-group">
              <label htmlFor="sign-sign_in_email" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_in_email"
                name="email"
                type="text"
                className="shiny-input-text form-control shiny-bound-input"
                placeholder={translations.email_sign_up}
              />
            </div>
            <div className="form-group">
              <label htmlFor="sign-sign_up_password" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_up_password"
                type="password"
                name="password"
                className="shiny-input-text form-control shiny-bound-input"
                placeholder={translations.password_sign_up}
              />
            </div>
            <div className="form-group">
              <label htmlFor="sign-sign_up_verify_password" className='control-label shiny-label-null'></label>
              <input
                id="sign-sign_up_verify_password"
                type="password"
                name="confirm_password"
                className="shiny-input-text form-control shiny-bound-input"
                placeholder={translations.verify_password_sign_up}
              />
            </div>
            <Button altColor={true}>
              {translations.sign_up}
            </Button>
          </form>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
