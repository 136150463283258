import { useAppContext } from '../lib/stores/AppContext';
import songsIcon from '../lib/assets/img/songs.png';
import birdIcon from '../lib/assets/img/bird.png';
import userIcon from '../lib/assets/img/user.png';
import progressIcon from '../lib/assets/img/progress.png';
import SongBirdHomeIcon from '../components/SongBirdHomeIcon';

const SongbirdHome: React.FC<{
    setActiveTab: (tab: string) => void;
}> = ({ setActiveTab }) => {

    const { translations } = useAppContext();

    return (
        <div className="tab-pane active">
            <div style={{ textAlign: 'center' }}>
                <table className="singbpause-table" style={{ margin: '0 auto' }}>
                    <tbody>
                        <tr>
                            <SongBirdHomeIcon
                                activeTabFun={() => setActiveTab('My Sing-Training')}
                                headerText={translations.my_sing_training_button}
                                icon={birdIcon} />

                            <SongBirdHomeIcon
                                activeTabFun={() => setActiveTab('Your Items')}
                                headerText={translations.my_singpause_songs_button}
                                icon={songsIcon} />

                        </tr>
                        <tr>
                            <SongBirdHomeIcon
                                activeTabFun={() => setActiveTab('Your Progress')}
                                headerText={translations.your_progress}
                                icon={progressIcon} />

                            <SongBirdHomeIcon
                                activeTabFun={() => setActiveTab('Account Details')}
                                headerText={translations.account_details}
                                icon={userIcon} />

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SongbirdHome;