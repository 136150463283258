import { ReactNode } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import toast, { Toaster } from 'react-hot-toast';

const Wrapper: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <>
            <Header />
            <div id="wrapper">
                <Toaster />
                <div id="dynamic_ui" aria-live="polite">
                    <div id="inner-wrapper">

                        {children}
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )

}

export default Wrapper;