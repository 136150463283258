import singingImg from '../lib/assets/img/singing.png';
import recordIcon from '../lib/assets/img/record.gif';
import React, { useRef, useState, useEffect } from 'react';
import * as Helper from '../lib/helpers';
import Lyrics from '../components/Lyrics';
import TrialData from "../lib/assets/js/TrialData";
import Feedback from "../components/Feedback";
import Button from '../components/Button';

interface PlayAudioProps {
    audioFile: string;
    useButtonInsteadOfPlayer?: boolean;
    onFinishLearning: () => void;
    lyricsFile?: string;
    songLearningType?: string;
    fullSong: boolean;
    metaData?: any
}

const PlayAudio: React.FC<PlayAudioProps> = ({ audioFile,
    useButtonInsteadOfPlayer = true,
    onFinishLearning,
    lyricsFile,
    songLearningType = "solo",
    fullSong = false,
    metaData }) => {


    const previewInit = songLearningType === "singalong";
    const [getFeedback, setGetFeedback] = useState(false);
    const [audioFileName, setAudioFileName] = useState("");
    const [stimulus, setStimulus] = useState({});
    const [preview, setPreview] = useState(previewInit);
    const [attempt, setAttempt] = useState(1);

    useEffect(() => {

        setStimulus({
            stimulus: metaData.abs_melody,
            stimulus_durations: metaData.durations,
        });

    }, [metaData]);


    const trialDataObj = new TrialData({
        stimuli: metaData.abs_melody,
        stimuli_durations: metaData.durations,
        item_id: metaData.item_id,
        attempt: attempt
    });

    const player = useRef<HTMLAudioElement | null>(null);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Function to handle the play action
    const handleClickPlay = (type = "solo", preview = true) => {
        document.getElementById("playButton").style.display = "none";
        const nextButton = document.getElementById("nextButton");
        if (nextButton !== null) {
            nextButton.style.display = "block";
        }
        if (player.current) {
            player.current.play();
        }
        if (type == "singalong" && !preview) {
            const recordIcon = document.getElementById("recordIcon");
            recordIcon.style.display = "block";
            Helper.startRecording();
        }

    };

    const handleAudioEndedSolo = () => {
        console.log('Audio playback has finished.');
        Helper.startRecording();
        const stopButton = document.getElementById("stopButton");
        stopButton.style.display = "block";
        const recordIcon = document.getElementById("recordIcon");
        recordIcon.style.display = "block";
    };

    const handleAudioEndedSingAlong = async () => {
        console.log('Audio playback has finished.');
        document.getElementById("recordIcon").style.display = "none"
        document.getElementById("mainTrialInfo").style.display = "none";
        let fileName = await Helper.uploadRecord(trialDataObj);
        setAudioFileName(fileName + ".wav");
        setGetFeedback(true);
        await delay(1000);
    };

    const handleAudioEndedPreview = async () => {
        console.log('Audio playback has finished.');
        await delay(1000);
    };

    const handleClickStop = async () => {
        console.log("Stop clicked");
        // Make sure nothing playing
        if (player.current) {
            player.current.pause();
        }
        let fileName = await Helper.uploadRecord(trialDataObj);
        setAudioFileName(fileName + ".wav");
        document.getElementById("playButton").style.display = "none";
        document.getElementById("stopButton").style.display = "none";
        document.getElementById("lyrics").style.display = "none";
        document.getElementById("recordIcon").style.display = "none";
        setGetFeedback(true);
    }

    function endPreview() {

        console.log("End preview!");

        if (player.current) {
            player.current.pause();
            player.current.currentTime = 0;
        }
        document.getElementById("playButton").style.display = "block";
        setPreview(false);

    }

    return (
        <div id="page">
            <div id="mainTrialInfo">
                {
                    preview ? (
                        <h2>Vorschau</h2>
                    ) : (
                        !preview && songLearningType === "solo" ? (
                            <h2>Singe das Lied nach</h2>
                        ) : (
                            <h2>Singe das Lied mit</h2>
                        )
                    )
                }

                <img src={singingImg} height={100} width={100} style={{ display: "block" }} />
                <Lyrics lyricsFile={lyricsFile} />
                <audio src={"/audio/" + audioFile} controls={true} id="player"
                    ref={player}
                    onEnded={
                        songLearningType === "singalong"
                            ? (preview ? handleAudioEndedPreview : handleAudioEndedSingAlong)
                            : handleAudioEndedSolo
                    }
                    style={{ display: useButtonInsteadOfPlayer ? "none" : "block" }} />
            </div>
            {useButtonInsteadOfPlayer && (
                <>
                    <Button id="playButton" onClick={() => handleClickPlay(songLearningType, preview)}>Play</Button>
                    <Button id="stopButton"
                        onClick={handleClickStop}
                        style={{ display: "none" }}>Stop</Button>
                    <img src={recordIcon} style={{ display: "none" }} id="recordIcon" />
                    {fullSong || preview && <Button id="nextButton" onClick={preview ? endPreview : handleClickStop} style={{ display: "none" }}>Next</Button>}
                </>
            )
            }
            {!preview && (
                <Feedback getFeedback={getFeedback}
                    audioFileName={audioFileName}
                    stimulus={stimulus}
                    onNextClick={preview ? endPreview : onFinishLearning}
                    setAttempt={setAttempt}
                    attempt={attempt}
                    setGetFeedback={setGetFeedback}
                    songLearningType={songLearningType} />
            )}
        </div >
    );
}

export default PlayAudio;