import { useAppContext } from '../lib/stores/AppContext';

const SignedInButtons: React.FC<{
    logoutUser: () => void
}> = ({ logoutUser }) => {

    const { translations } = useAppContext();

    return (

        <div id="signed_in_buttons">
            <button
                id="sign-sign_out_button"
                onClick={(e) => {
                    e.preventDefault();
                    logoutUser();
                }}
                type="button"
                className="btn btn-default action-button"
            >
                {translations.sign_out}
            </button>
        </div>
    )
}

export default SignedInButtons;