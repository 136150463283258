
import { motion } from 'framer-motion';

const SongBirdHomeIcon: React.FC<{
    activeTabFun: () => void;
    headerText: string;
    icon: string
}> = ({ activeTabFun, headerText, icon }) => {

    return (

        <motion.td whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 500 }}>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    activeTabFun();
                }}
            >
                <h3>{headerText}</h3>
                <img
                    className="singpause-menu"
                    src={icon}
                    alt={headerText}
                />
            </a>
        </motion.td>

    );
}

export default SongBirdHomeIcon;