
import React, { useState, useEffect } from 'react';
import * as Helper from '../lib/helpers';
import BirdAnimation from '../components/BirdAnimation';
import Button from '../components/Button';
import trophyIcon from '../lib/assets/img/trophy.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface FeedbackProps {
    getFeedback: boolean;
    audioFileName: string;
    stimulus: any;
    onNextClick: () => void;
    lastPage?: boolean;
    setActiveTab?: (tab) => void;
    setAttempt: (tab) => void;
    attempt: number;
    setGetFeedback: (get) => void;
    songLearningType?: string
    setGotSingTrainingItems?: (hasGot) => void;
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const Feedback: React.FC<FeedbackProps> = ({ getFeedback,
    audioFileName,
    stimulus,
    onNextClick,
    lastPage = false,
    setActiveTab,
    setAttempt,
    attempt,
    setGetFeedback,
    songLearningType = "solo",
    setGotSingTrainingItems }) => {


    const feedbackInit = <><BirdAnimation /> <h3>Fetching feedback...</h3></>;

    const [feedbackSuccessful, setFeedbackSuccessful] = useState(false);
    const [feedback, setFeedback] = useState(feedbackInit);

    const handleGetFeedback = async (fileName) => {

        console.log('handleGetFeedback');
        console.log(fileName);

        let count = 0;

        const url = `${API_BASE_URL}/v2/get-job-status`;

        const payload = {
            filename: fileName
        };

        console.log('fileName: ' + fileName);

        console.log("Waiting 3 seconds...");
        await delay(3000);

        console.log(getFeedback);
        console.log(count);
        console.log(feedbackSuccessful);

        while (getFeedback && count < 20 && !feedbackSuccessful) {


            try {
                console.log("Trying...");
                console.log("Count: " + count);
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                });

                console.log(response);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();

                setFeedbackSuccessful(true);

                return result;
            } catch (error) {
                console.log(error);
                count++;
                console.log("Waiting 1 second...");
                await delay(1000);
            }

        }

        if (count === 20) {
            setFeedback(<div>Could not get feedback.</div>)
        }

    };

    function convertTo3ColumnArray(arr) {
        const result = [[], [], []]; // Prepare 3 empty sub-arrays for the columns

        arr.forEach((value, index) => {
            result[index % 3].push(value); // Distribute values across the three columns
        });

        return result;
    }

    useEffect(() => {


        function getFeedbackText(score) {

            let feedback;

            if (score >= 0 && score <= 2) {
                feedback = "Das kannst du besser!";
            } else if (score >= 3 && score <= 4) {
                feedback = "Schon ziemlich gut!";
            } else if (score >= 5 && score <= 7) {
                feedback = "Gut gemacht!";
            } else if (score >= 8 && score <= 9) {
                feedback = "Super, du bist klasse!";
            } else if (score === 10) {
                feedback = "Gratulation, das war hervorragend!";
            } else {
                feedback = "Ungültige Punktzahl";
            }

            return feedback;
        }

        function someZeroOrNaN(arr) {
            return arr.some(value => value === 0 || Number.isNaN(value));
        }


        const fetchFeedback = async () => {

            if (getFeedback) {

                const mainTrialInfo = document.getElementById("mainTrialInfo");
                mainTrialInfo.style.display = "none";

                console.log('audioFileName');
                console.log(audioFileName);
                const response = await handleGetFeedback(audioFileName);

                if (typeof (response) !== 'undefined') {
                    const message = response.message;

                    const lines = message.split('\n');
                    const result = lines.flatMap(line => line.split(',').map(num => parseFloat(num)));
                    const parsedResult = convertTo3ColumnArray(result);

                    console.log(parsedResult);

                    const freqs = parsedResult[2];

                    console.log('freqs.length', freqs.length);

                    console.log(freqs);

                    console.log(someZeroOrNaN(freqs));

                    if (freqs.length < 1 || someZeroOrNaN(freqs)) {
                        setFeedback(<div>Could not get feedback. Maybe you didn't sing enough notes.</div>);
                    } else {

                        const freqsNumeric = freqs.map(Number);
                        const midi = freqsNumeric.map(Helper.frequencyToMidi);
                        const user_intervals = midi.slice(1).map((num, index) => num - midi[index]);

                        const onsets = parsedResult[0];
                        let onsetsNumeric = onsets.map(Number);
                        let iois = onsetsNumeric.slice(1).map((num, i) => num - onsetsNumeric[i]);

                        const durs = parsedResult[1];
                        let dursNumeric = durs.map(Number);

                        const melody = stimulus.stimulus.split(",").map(Number);
                        const melody_intervals = melody.slice(1).map((num, i) => num - melody[i]);
                        let durations = stimulus.stimulus_durations.split(",").map(Number);

                        // Force the last rhythm to be the same
                        iois[iois.length - 1] = 0.50;
                        durations[durations.length - 1] = 0.50;

                        const ngrukkon = Math.ceil(Helper.ngrukkon(melody_intervals, user_intervals) * 10);
                        const rhythfuzz = Math.ceil(Helper.rhythfuzz(durations, iois) * 10);
                        const jointScore = Math.ceil((ngrukkon + rhythfuzz) / 2);

                        const feedback = <div>
                            <h3>Your Results</h3>
                            <img src={trophyIcon} height={100} width={100} />
                            <h3>Your score was: {jointScore}/10 </h3>
                            <p>{getFeedbackText(jointScore)}</p>

                            <div style={{ display: 'flex', gap: '20px', textAlign: 'center', margin: 'auto', width: '200px' }}>
                                <div style={{ width: '100px', height: '150px' }}>
                                    <CircularProgressbar
                                        value={ngrukkon}
                                        text="Melody"
                                        maxValue={10}
                                        styles={buildStyles({
                                            pathColor: '#A5C54D',
                                            trailColor: '#f7f7da',
                                            textColor: '#000',
                                            textSize: '14px'
                                        })}
                                    />
                                </div>
                                <div style={{ width: '100px', height: '150px' }}>
                                    <CircularProgressbar
                                        value={rhythfuzz}
                                        text="Rhythms"
                                        maxValue={10}
                                        styles={buildStyles({
                                            pathColor: '#f6a243',
                                            trailColor: '#f7f7da',
                                            textColor: '#000',
                                            textSize: '14px'
                                        })}
                                    />
                                </div>
                            </div>

                        </div>;

                        setFeedback(feedback);

                    }

                }

            }
        };

        fetchFeedback();

    }, [getFeedback]);


    const handleNewAttempt = (newAttemptNumber) => {

        const mainTrialInfo = document.getElementById("mainTrialInfo");
        mainTrialInfo.style.display = "block";
        const playButton = document.getElementById("playButton");
        if(playButton !== null) {
            playButton.style.display = "block";
        }
        document.getElementById("postFeedbackButtons").style.display = "none";
        setFeedback(feedbackInit);
        setGetFeedback(false);
        setFeedbackSuccessful(false);
        setAttempt(newAttemptNumber);
        console.log("new attempt: ", newAttemptNumber);
    }

    const lastPageHandler = () => {
        onNextClick(); 
        setActiveTab('SongBird');
        setGotSingTrainingItems(false);
    }

    return (
        <>
            {getFeedback && feedback}
            {feedbackSuccessful && (
                <div id="postFeedbackButtons">
                    <Button onClick={() => handleNewAttempt(attempt + 1)}> Try Again </Button>
                    <Button onClick={ lastPage ? lastPageHandler : onNextClick }>Next</Button>
                </div>
            )}
        </>
    )

}

export default Feedback;