
/* eslint-disable no-restricted-globals */

import React, { useState, useEffect, useRef } from 'react';
import { signOut } from '@aws-amplify/auth';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import 'react-circular-progressbar/dist/styles.css';
import appConfig from '../lib/app-config.json';
import Wrapper from '../components/Wrapper';
import Navigation from '../components/Navigation';
import SignedInHeader from '../components/SignedInHeader';
import TabContent from '../components/TabContent';
import MicModal from '../components/MicModal';
import { useAppContext } from '../lib/stores/AppContext';
import "../lib/Cognito";
import * as Helper from '../lib/helpers';
import AutoLogout from './AutoLogout';
const APP_URL = process.env.REACT_APP_APP_URL ?? '';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Dashboard: React.FC = () => {


    const [activeTab, setActiveTab] = useState(Object.keys(appConfig.content.tabsToShow[0])[0]);
    const { translations, userId, session, isAuthenticated, sessionToken, currentLanguage } = useAppContext();
    const [tests, setTests] = useState([]);
    const [experiments, setExperiments] = useState([]);
    const [gotSingTrainingItems, setGotSingTrainingItems] = useState(false);
    const [selectedSingTrainingItems, setSelectedSingTrainingItems] = useState({});
    const [yourItems, setYourItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [hasExperiments, setHasExperiments] = useState(true);
    const [hasTests, setHasTests] = useState(true);
    const [hasGroups, setHasGroups] = useState(true);
    const formRef = useRef(null);
    const [micActive, setMicActive] = useState(false);
    const [learningSong, setLearningSong] = useState(false);
    const [sessionId, setSessionId] = useState(NaN);


    const getUserGroups = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/v2/get-user-groups`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: userId }) // Replace with actual user ID
            });
            const result = await response.json();
            setGroups(result.groups || []);
            setHasGroups(result.groups?.length > 0);
        } catch (error) {
            console.error(error);
        }
    };

    const appendSession = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/append-session`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user_id: userId
                }) 
            });
            const result = await response.json();
            setSessionId(result.session_id);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddUserToGroup = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const loginCode = formData.get('login-code') as string;

        try {
            const response = await fetch(`${API_BASE_URL}/v2/add-user-to-group-with-login-code`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: userId, login_code: loginCode }) // Replace with actual user ID
            });
            const result = await response.json();

            if (result.message.includes('successfully')) {
                toast.success(result.message);
                await getUserGroups();
            } else {
                toast.error(result.message);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const endSession = async () => {


        console.log('endSession called');
        
        const url = `${API_BASE_URL}/compute-session-scores-and-end-session`;

        const payload = {
            test_id : 2, 
            session_id : sessionId, 
            user_id: parseInt(userId),
            psychTestR_session_id : "",
            session_complete: "1", 
            user_info: ""
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload),
            });

            console.log(response);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            console.log(result);

            return result;
        } catch (error) {
            return;
        }

    }

    const logoutUser = async () => {
        try {
            endSession();
            await signOut();
            toast.dismiss();
            window.location.href = '/';
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };


    const getAvailableTestsandExperiments = async (userId) => {
        const url = `${API_BASE_URL}/v2/get-available-tests-and-experiments`;
        const payload = {
            user_id: parseInt(userId),
            app_name: "singpause",
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            return result;
        } catch (error) {
            return;
        }
    };


    const selectItems = async (userId) => {

        console.log("selectItems called");

        console.log(userId);

        const url = `${API_BASE_URL}/select-items`;

        const payload = {
            user_id: userId
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                console.log('Got items!');
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            console.log(result);

            setGotSingTrainingItems(true);
            setSelectedSingTrainingItems(result);

            // return result;
        } catch (error) {
            console.error('Error fetching items');
            console.log(error);
        }
    };


    const getSingItems = async (userId) => {


        const url = `${API_BASE_URL}/v2/get-singpause-items`;
        const payload = {
            user_id: parseInt(userId),
        };
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            return result["songs"];

        } catch (error) {
            return;
        }
    };

    if (!micActive) {
        Helper.microTest();
    }

    useEffect(() => {

        const fetchData = async () => {

            const response = await getAvailableTestsandExperiments(
                session?.userId,
            );

            const groups: any = await getUserGroups();

            setHasExperiments(response?.experiments?.length > 0 ? true : false);
            setHasTests(response?.tests?.length > 0 ? true : false);
            setHasGroups(groups?.length > 0 ? true : false);
            setGroups(groups?.groups);
            setTests(response.tests);
            setExperiments(response.experiments);
            setYourItems(await getSingItems(session?.userId));
            
            console.log('check... ', typeof (userId) !== "undefined" && !gotSingTrainingItems);

            if (typeof (userId) !== "undefined" && !gotSingTrainingItems) {
                const parsedUserId = parseInt(userId);
                console.log('check.2.. ', !Number.isNaN(parsedUserId));
                if (!Number.isNaN(parsedUserId)) {
                    selectItems(parsedUserId);
                }
            }

        };
        fetchData();
        appendSession();

    }, [session, gotSingTrainingItems]);

    return (
        <Wrapper>
            <AutoLogout logoutUser={logoutUser} />
            <MicModal showModal={!micActive} onClose={() => setMicActive(true)} />
            <SignedInHeader session={session} />
            <Navigation setActiveTab={setActiveTab} activeTab={activeTab} setLearningSong={setLearningSong} />
            <TabContent session={session}
                activeTab={activeTab}
                tests={tests}
                setTests={setTests}
                hasTests={hasTests}
                yourItems={yourItems}
                setActiveTab={setActiveTab}
                logoutUser={logoutUser}
                SingTrainingItems={selectedSingTrainingItems}
                gotSingTrainingItems={gotSingTrainingItems}
                learningSong={learningSong}
                setLearningSong={setLearningSong}
                setGotSingTrainingItems={setGotSingTrainingItems} />
        </Wrapper>
    );
};

export default Dashboard;
