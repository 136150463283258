import appConfig from '../lib/app-config.json';

const AvailableTests: React.FC<{
    setTests: (tests: string[]) => void;
    hasTests: boolean;
    tests: any[]
}> = ({ setTests, hasTests, tests }) => {

    return (
        <div className="tab-pane active">
            {tests.length > 0 ? (
                <>
                    <h2>{appConfig.content.testsHeading}</h2>
                    {tests.map((test) => (
                        <div key={test.test_name}>
                            <hr />
                            <h3>{test.test_description}</h3>
                            <img
                                src={test.test_image}
                                height="100"
                                width="100"
                                alt={test.test_description}
                            />
                            <button className="btn launch_test">
                                Launch Test!
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                            </button>
                            <hr />
                        </div>
                    ))}
                </>
            ) : !hasTests ? (
                <div>
                    <p>Before you can complete any tests or experiments, you need to fill out your profile by answering some demographic questions.</p>
                    <p>Click Edit Profile below to fill out your profile. The questionnaires will take 5-10 minutes.</p>
                    <button className="btn">
                        Edit Profile
                    </button>
                </div>
            ) : (
                <div className="animated-background">
                    <div className="background-masker"></div>
                </div>
            )}
        </div>
    )
}

export default AvailableTests;