import React, { useState } from 'react';
import Modal from '../components/Modal';

interface ModalProps {
  showModal: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const SignUpModal: React.FC<ModalProps> = ({ showModal, onConfirm, onClose }) => {
  const [agreeInformed, setAgreeInformed] = useState(false);
  const [agreeRecording, setAgreeRecording] = useState(false);
  const [agreeParticipation, setAgreeParticipation] = useState(false);
  const [showContent, setShowContent] = useState<'main' | 'freiwilligkeit' | 'datenschutz'>('main');

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (agreeInformed && agreeRecording && agreeParticipation) {
      onConfirm();
      onClose();
    } else {
      alert('Sie müssen allen Bedingungen zustimmen.');
    }
  };

  const showFreiwilligkeit = () => setShowContent('freiwilligkeit');
  const showDatenschutz = () => setShowContent('datenschutz');
  const showMain = () => setShowContent('main');

  return showModal ? (
    <Modal onClose={onClose} showModal={showModal}>
        {showContent === 'main' && (
          <>
            <h2 className="header">Einverständniserklärung</h2>
            <br />
            <form onSubmit={handleFormSubmit}>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="agreeInformed"
                  checked={agreeInformed}
                  onChange={() => setAgreeInformed(!agreeInformed)}
                />
                <label htmlFor="agreeInformed">
                  Mein Kind und ich sind schriftlich im ausreichenden Maße über das Ziel und den Ablauf der Studie informiert worden und haben die Informationen zur{' '}
                  <span className="underline" onClick={showFreiwilligkeit}>
                    Freiwilligkeit
                  </span>{' '}
                  und{' '}
                  <span className="underline" onClick={showDatenschutz}>
                    Datenschutz
                  </span>{' '}
                  erhalten.
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="agreeRecording"
                  checked={agreeRecording}
                  onChange={() => setAgreeRecording(!agreeRecording)}
                />
                <label htmlFor="agreeRecording">
                  Ich bin darüber informiert, dass die Nutzung der App SongBird die Verwendung von Kopfhörern und Mikrofon vorsieht und beim Sing-Training eine Aufzeichnung der Singstimme stattfindet.
                </label>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="agreeParticipation"
                  checked={agreeParticipation}
                  onChange={() => setAgreeParticipation(!agreeParticipation)}
                />
                <label htmlFor="agreeParticipation">
                  Ich willige ein, dass mein Kind an der Studie teilnehmen und die App SongBird nutzen darf.
                </label>
              </div>
              <button type="submit" className="button">Registrieren</button>
            </form>
          </>
        )}

        {showContent === 'freiwilligkeit' && (
          <>
            <button className="back-button" onClick={showMain}>← Zurück</button>
            <h2 className="header" style={{ textAlign: 'left' }}>Freiwilligkeit:</h2>
            <p>
              Die Teilnahme an der Studie ist freiwillig. Sie bzw. Ihre Tochter/Ihr Sohn können jederzeit und ohne Angabe von Gründen die Teilnahme an dieser Studie beenden, ohne dass Ihnen daraus Nachteile entstehen.
            </p>
          </>
        )}

        {showContent === 'datenschutz' && (
          <>
            <button className="back-button" onClick={showMain}>← Zurück</button>
            <h2 className="header" style={{ textAlign: 'left' }}>Datenschutz:</h2>
            <p>
              Die Erhebung aller Daten erfolgt vollständig anonymisiert. Alle Daten werden auf einem Amazon Elastic Compute Cloud (EC2) Server in Frankfurt gespeichert.
            </p>
          </>
        )}
     </Modal>
  ) : null;
};

export default SignUpModal;
