import { useAppContext } from '../lib/stores/AppContext';
import appConfig from '../lib/app-config.json';

const Navigation: React.FC<{
    setActiveTab: (tabName: string) => void;
    activeTab: string;
    setLearningSong: (learningSong) => void
}> = ({ setActiveTab, activeTab, setLearningSong }) => {

    const { translations } = useAppContext();

    return (
        <ul className="nav nav-tabs" data-tabsetid="1719">
            {appConfig.content.tabsToShow.map((tab, index) => {
                // Each tab is an object, so get the key and value
                const [key, label] = Object.entries(tab)[0];
                return (
                    <li key={index} className={activeTab === key ? 'active' : ''}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(key);
                            }}
                        >
                            {label}
                        </a>
                    </li>
                );
            })}

            {activeTab !== 'SongBird' && (
                <>
                    <li className={activeTab === 'Tests' ? 'active' : ''}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab('My Sing-Training');
                            }}
                        >
                            {translations.my_sing_training_button}
                        </a>
                    </li>
                    <li className={activeTab === 'Your Items' ? 'active' : ''}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setLearningSong(false);
                                setActiveTab('Your Items');
                            }}
                        >
                            {translations.my_singpause_songs_button}
                        </a>
                    </li>
                    <li className={activeTab === 'Your Progress' ? 'active' : ''}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab('Your Progress');
                            }}
                        >
                            {translations.your_progress}
                        </a>
                    </li>
                    <li className={activeTab === 'Account Details' ? 'active' : ''}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab('Account Details');
                            }}
                        >
                            {translations.account_details}
                        </a>
                    </li>
                </>
            )}
        </ul>
    )

}

export default Navigation;