import { useAppContext } from '../lib/stores/AppContext';

const SignedInHeader = ({ session }) => {

    const { translations } = useAppContext();
    const username = session?.username;
    return (
        <div className="content-title">
            <div id="sign-signed_in_title" aria-live="polite">
                { username && ( <p>{translations.welcome + ", " + " " + username}</p> ) }
            </div>
            <br />
        </div>
    )
}

export default SignedInHeader;