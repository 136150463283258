import MelodyPage from './MelodyPage';

// Note, this isn't an actual component, but a helper function

const trialBlock = (melodies, incrementPage, showNextButton = true, setGotSingTrainingItems, setActiveTab?) => {

    const melodiesParsed = [...melodies.new_items, ...melodies.review_items];

    const trialBlockLength = melodiesParsed.length;

    const trialBlock = melodiesParsed.map((melody, idx) => {
        const pageNumber = idx + 1;
        const lastPage = trialBlockLength === pageNumber;
        return (
            <MelodyPage
                key={idx}
                melody={melody.abs_melody}
                durations={melody.durations}
                progress={idx + 1}
                onStop={incrementPage}
                showNextButton={showNextButton}
                onNextClick={incrementPage}
                metaData={melody}
                trialBlockLength={trialBlockLength}
                lastPage={lastPage}
                setActiveTab={setActiveTab}
                setGotSingTrainingItems={setGotSingTrainingItems}
            />)
    });

    console.log(trialBlock);

    return (trialBlock);

}

export default trialBlock;