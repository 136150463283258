import React, { useState } from 'react';
import { useAppContext } from '../lib/stores/AppContext';
import { deleteUser, updatePassword } from '@aws-amplify/auth';
import toast, { Toaster } from 'react-hot-toast';
import SignedInButtons from '../components/SignedInButtons';

const AccountDetails: React.FC<{ logoutUser: () => void }> = ({ logoutUser }) => {

    const { translations } = useAppContext();
    const [removeAccount, setRemoveAccount] = useState(false);

    const handleUpdatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const oldPassword = formData.get('current-password') as string;
        const newPassword = formData.get('new-password') as string;
        const confirmNewPassword = formData.get('confirm-new-password') as string;

        if (newPassword !== confirmNewPassword) {
            toast.error('Passwords do not match');
            return;
        }

        try {
            await updatePassword({ oldPassword, newPassword });
            await logoutUser();
            toast.success('Password successfully changed.');
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleDeleteUser = async () => {
        try {
            await deleteUser();
            await logoutUser();
            toast.success('Your account has been successfully removed.');
        } catch (error) {
            console.error(error);
        }
    };

    return (

        <div className="tab-pane active" style={{ margin: '0 auto' }}>
            <form onSubmit={handleUpdatePassword} className='signed-in-form' style={{margin : '60px'}}>
                <div className="form-group">
                    <label htmlFor="signed_in_current_password">{translations.change_password}</label>
                    <hr />
                    <input
                        id="signed_in_current_password"
                        type="password"
                        name="current-password"
                        className="shiny-input-password form-control shiny-bound-input"
                        placeholder={translations.current_password}
                    />
                </div>
                <div className="form-group">
                    <input
                        id="sign-signed_in_new_password"
                        type="password"
                        name="new-password"
                        className="shiny-input-password form-control shiny-bound-input"
                        placeholder={translations.new_password}
                    />
                </div>
                <div className="form-group" >
                    <input
                        id="sign-signed_in_verify_new_password"
                        type="password"
                        name="confirm-new-password"
                        className="shiny-input-password form-control shiny-bound-input"
                        placeholder={translations.verify_password}
                    />
                </div>
                <button type="submit" className="btn btn-default action-button shiny-bound-input">
                    {translations.change_password_button}
                </button>
                {removeAccount && (
                    <button
                        className="btn caution-button"
                        onClick={handleDeleteUser}
                    >
                        {translations.remove_account}
                    </button>
                )}
            </form>
            <SignedInButtons logoutUser={logoutUser} />
        </div>
    )
}

export default AccountDetails;