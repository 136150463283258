import record from '../lib/assets/img/record.gif';
import * as Helper from '../lib/helpers';
import Feedback from '../components/Feedback';
import { useState, useEffect } from 'react';
import TrialData from "../lib/assets/js/TrialData";
import singingImg from '../lib/assets/img/singing.png';
import Button from '../components/Button';



const MelodyPage: React.FC<{
    progress?: number;
    showFirstNoteButton?: boolean;
    melody: number[] | string;
    durations: number[] | string;
    onStop?: () => void;
    onNextClick: () => void;
    showNextButton: boolean;
    metaData: any;
    trialBlockLength: number;
    lastPage: boolean;
    setActiveTab?: () => void;
    setGotSingTrainingItems: (hasGot) => void;
}> = ({ progress = 1,
    showFirstNoteButton = false,
    melody,
    durations,
    onStop,
    showNextButton = true,
    onNextClick,
    metaData,
    trialBlockLength,
    lastPage = false,
    setActiveTab,
    setGotSingTrainingItems }) => {

        const [getFeedback, setGetFeedback] = useState(false);
        const [audioFileName, setAudioFileName] = useState("");
        const [stimulus, setStimulus] = useState({});
        const [attempt, setAttempt] = useState(1);
        const [transposedMelody, setTransposedMelody] = useState([]);
        const [preppedDurations, setPreppedDurations] = useState([]);

        useEffect(() => {

            if (typeof (melody) === 'string') {
                melody = melody.split(",").map(Number) as number[];;
            }

            if (typeof (durations) === 'string') {
                durations = durations.split(",").map(Number) as number[];
                setPreppedDurations(durations);
            }

            // Transpose melody into range
            const transposedMelody = Helper.getBestTranspositionForRange(melody);
            setTransposedMelody(transposedMelody);

            setStimulus({
                stimulus: transposedMelody.join(", "),
                stimulus_durations: metaData.durations,
            });
        }, [metaData]); // Only re-run when metaData changes

        let stimulusForMetaData = "";
        let trialDataObj;

        if (typeof transposedMelody === "undefined" || transposedMelody.length === 0) {
            stimulusForMetaData = "";
        } else {
            stimulusForMetaData = transposedMelody.join(", ");

            trialDataObj = new TrialData({
                stimuli: stimulusForMetaData,
                stimuli_durations: typeof metaData.durations === "undefined" ? "" : metaData.durations,
                item_id: metaData.item_id,
                new_items_id: metaData.new_items_id,
                review_items_id: metaData.review_items_id,
                attempt: attempt
            });

        }

        const firstNote = typeof transposedMelody !== "undefined" && typeof transposedMelody[0] === "number" ? (transposedMelody[0] as number) : null;

        const handlePlayFirstNote = (note: number) => {
            Helper.playSingleNote(note, 0.5, 'piano');
        };

        const handlePlaySequence = (melody: number[], durations: number[]) => {
            Helper.playSeq(melody, durations, 'piano', null);
            document.getElementById('play')!.style.display = 'none';
        };

        const handleStopRecording = async () => {
            // Wait for the promise to resolve and get the fileName
            let fileName = await Helper.uploadRecord(trialDataObj);
            setAudioFileName(fileName + ".wav");
            // DOM Manipulation to switch between recording and playing UI
            document.getElementById('rec').style.display = 'none';
            document.getElementById('play').style.display = 'block';
            setGetFeedback(true);
        };

        return (
            <>
                <div id="mainTrialInfo">
                    <h3>Section Progress: {progress}/{trialBlockLength} </h3>
                    <p>Press play to hear the melody. Play back the melody.</p>
                    <img src={singingImg} height={100} width={100} style={{ display: "block" }} />
                    <div id="play" style={{ display: 'block' }}>
                        {showFirstNoteButton && <><p> The first note is:</p> <Button onClick={() => (handlePlayFirstNote(firstNote))}>Play First Note</Button>)</>}
                        <Button onClick={() => handlePlaySequence(transposedMelody as number[], preppedDurations as number[])}>Play</Button>
                    </div>
                </div>
                <div id="rec" style={{ display: 'none' }}>
                    <img id="recordImage" src={record} alt="record" width="250" height="250" />
                    <Button id="stop" onClick={handleStopRecording}>Stop</Button>
                </div>
                {showNextButton && <button onClick={onNextClick} className='btn'>Next</button>}
                <Feedback getFeedback={getFeedback} audioFileName={audioFileName} stimulus={stimulus}
                    onNextClick={onNextClick} lastPage={lastPage} setActiveTab={setActiveTab}
                    setAttempt={setAttempt} attempt={attempt} setGetFeedback={setGetFeedback}
                    setGotSingTrainingItems={setGotSingTrainingItems} />
            </>
        );
    }

export default MelodyPage;

