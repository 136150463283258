import React from 'react';

const Timeline: React.FC<{
    timeline: any[] | JSX.Element | React.ReactNode;
    pageNumber: number
}> = ({ timeline, pageNumber }) => {

    function isJSXElement(element: any): element is JSX.Element {
        return (
            typeof element === 'object' &&
            element !== null &&
            'type' in element &&
            'props' in element
        );
    }

    if (isJSXElement(timeline)) {
        timeline = React.Children.toArray(timeline);
    }

    const page = timeline[pageNumber - 1];
    return (
        <div id="page">
            {page}
        </div>

    )
}

export default Timeline;