import BirdAnimation from '../components/BirdAnimation';

interface LoadingProps {
    text?: any;
    children?: any
}

const Loading: React.FC<LoadingProps> = ({ text, children }) => {
    return (
        <div id="loading" className="alignCenter">
            <h3>{text}</h3>
            <BirdAnimation style={{ height: "150px", width: "150px", marginTop: "40px" }} />
            {children}
        </div>
    )
}
export default Loading;