import React, { useEffect, useState } from 'react';

const Lyrics: React.FC<{ lyricsFile: string }> = ({ lyricsFile }) => {

  const [lines, setLines] = useState([]);

  useEffect(() => {
    console.log("lyricsFile: " + lyricsFile);
    // Fetch the file from the public directory
    fetch('/lyrics/' + lyricsFile)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((text) => {
        const linesArray = text.split(/\r?\n/); // Split by newlines
        setLines(linesArray);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  }, []);

  return (
    <div id="lyrics">
      <h3>Songtext</h3>
      {lines.map((line, index) => {
        const parsedLine = line.split("->")[0].trim();
        return <p key={index}>{parsedLine}</p>;
      })}
    </div>
  );

}

export default Lyrics;
