import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { signIn, signUp } from "@aws-amplify/auth";
import toast, { Toaster } from "react-hot-toast";
import songbirdIcon from "../lib/assets/img/songbird_icon.png";
import { useAppContext } from "../lib/stores/AppContext";
import SignUpModal from "./SignUpModal";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import YourProgress from './YourProgress';
import Modal from './Modal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Teacher: React.FC = () => {
    const [res, setRes] = useState<any>(null);
    const { translations, showModal, setShowModal, isAuthenticated, session, isAdmin } = useAppContext();
    const [chartData, setChartData] = useState<any>(null);
    const [groupsStats, setGroupsStats] = useState<any>(null);
    const [currentView, setCurrentView] = useState<string>("overall");
    const [loader, setLoader] = useState(true);
    const [avgMinutesPerDay, setAvgMinutesPerDay] = useState(0);
    const [avgNoPracticeSessions, setAvgNoPracticeSessions] = useState(0);
    const [signUpData, setSignUpData] = useState({ username: "", password: "", email: "", confirmPassword: "" });
    const [form, setForm] = useState<HTMLFormElement | null>(null);
    const [userSessionsData, setUserSessionsData] = useState([]);
    const [songStatsData, setSongStatsData] = useState([]);

    const [showChartModal, setShowChartModal] = useState(false);

    const [hasChartData, setHasChartData] = useState(false);

    const [studentChartData, setStudentChartData] = useState({
        session_scores_rhythmic: [],
        review_melodies: [],
        user_stats: [],
    });

    const filterByUserId = (data, userId) => {
        return data.filter((item) => item.user_id === userId);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const getGroupStats = async () => {
        const url = `${API_BASE_URL}/get-trial-and-session-data`;
        const payload = {
            group_id: 5,
            filter_pseudo_anonymous_ids: true,
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();


            setLoader(false);
            return result;
        } catch (error) {
            console.error("Failed to fetch group stats:", error);
        }
    };

    const updateDataHandlers = (view) => {
        if (!res) return;

        const userSessionsData = res[`${view}_avg_no_practice_session_per_user`].map(
            (userSession: any, index: number) => ({
                id: index,
                user_id: userSession.user_id,
                username: userSession.username,
                sessions: userSession[`${view}_no_practice_sessions`],
            })
        );

        const songStatsData = res[`${view}_song_stats`].map((songStat: any, index: number) => ({
            id: index,
            phrase_name: songStat.phrase_name,
            NoTimesPractised: songStat.NoTimesPractised,
            Score: songStat.Score || "N/A",
        }));

        setAvgMinutesPerDay(Math.round(res[`${view}_avg_minutes_per_day`] * 100) / 100);
        setAvgNoPracticeSessions(Math.round(res[`${view}_avg_no_practice_sessions`] * 100) / 100);
        setUserSessionsData(userSessionsData);
        setSongStatsData(songStatsData);
    };

    const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newView = event.target.value;
        setCurrentView(newView);
        updateDataHandlers(newView);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const username = formData.get("email") as string;
        const password = formData.get("password") as string;

        try {
            await signIn({ username, password });
            window.location.reload();
        } catch (error: any) {
            toast.error(error.message);
            if (error?.message.includes("The quota has been exceeded")) {
                localStorage.clear();
            }
        }
    };

    const handleSignUpButtonClick = (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        setSignUpData({
            username: formData.get("username") as string,
            password: formData.get("password") as string,
            email: formData.get("email") as string,
            confirmPassword: formData.get("confirm_password") as string,
        });
        setShowModal(true);
    };

    const handleSignUp = async () => {
        const { username, password, email, confirmPassword } = signUpData;
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        if (email && !validateEmail(email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        try {
            await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email,
                        "custom:isAdmin": "true",
                    },
                },
            });
            form?.reset();
            toast.success("Registered!");
            setShowModal(false);
        } catch (error: any) {
            toast.error(error.message);
            if (error?.message.includes("The quota has been exceeded")) {
                localStorage.clear();
            }
        }
    };

    const handleRowClick = (row: any) => {
        updateStudentChartData(row);
        setShowChartModal(true)
    };



    const updateStudentChartData = (row) => {
        if (chartData) {
            const filteredSessionScores = filterByUserId(chartData.session_scores_rhythmic, row.user_id);
            const filteredReviewMelodies = filterByUserId(chartData.review_melodies, row.user_id);
            const filteredUserStats = filterByUserId(chartData.user_stats, row.user_id);
            if (filteredReviewMelodies.length > 0 || filteredSessionScores.length > 0) {
                setHasChartData(true)
            } else {
                setHasChartData(false)
            }

            setStudentChartData((prevData) => ({
                ...prevData,
                session_scores_rhythmic: filteredSessionScores,
                review_melodies: filteredReviewMelodies,
                user_stats: filteredUserStats,
            }));
        }
    };

    useEffect(() => {
        if (isAdmin) {
            (async () => {
                const stats = await getGroupStats();
                setGroupsStats(stats);
                setRes(stats?.group_stats);
                updateDataHandlers("overall");
                setLoader(false);
                setChartData(stats);

            })();
        }
    }, [isAdmin]);


    useEffect(() => {
        if (res) {
            updateDataHandlers("overall");
        }
    }, [res]);

    const userSessionsColumns = [
        { name: "Username", selector: (row: any) => row.username, sortable: true },
        { name: "No. Practice Sessions", selector: (row: any) => row.sessions, sortable: true },
    ];

    const songStatsColumns = [
        { name: "Phrase Name", selector: (row: any) => row.phrase_name, sortable: true },
        { name: "No. Times Practised", selector: (row: any) => row.NoTimesPractised, sortable: true },
        { name: "Avg. Score", selector: (row: any) => row.Score, sortable: true },
    ];


    return (
        <div>
            <Header />
            <Toaster />
            <SignUpModal showModal={showModal} onConfirm={handleSignUp} onClose={() => setShowModal(false)} />

            {isAdmin ? (
                <div className="container" style={{ position: "relative" }}>
                    <br />

                    {res ? (
                        <>
                            <div className="dropdown-container">

                                <select onChange={handleViewChange} value={currentView}>
                                    <option value="" disabled>Select a view</option>
                                    <option value="overall">Overall</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="last_week">Last Week</option>
                                </select>

                            </div>
                            <hr />
                            <p>
                                The students have practiced an average of {avgMinutesPerDay} minutes per day and completed {avgNoPracticeSessions} practice sessions.
                            </p>
                            <hr />
                            <h2>User Statistics</h2>
                            <DataTable
                                className="tableHover"
                                columns={userSessionsColumns}
                                data={userSessionsData}
                                pagination
                                onRowClicked={handleRowClick}

                            />

                            <hr />
                            <h2>Song Statistics</h2>
                            <DataTable
                                className="tableHover"
                                columns={songStatsColumns}
                                data={songStatsData}
                                pagination
                                onRowClicked={handleRowClick}

                            />
                            <Modal onClose={() => setShowChartModal(false)} showModal={showChartModal}>

                                <YourProgress chartData={studentChartData} hasChartData={hasChartData} chartDataLoaded={true} />
                            </Modal>

                        </>
                    ) : loader ? (
                        <div className="animated-background"></div>
                    ) : null}
                </div>
            ) : (
                <div className="wrapper">
                    <Toaster />
                    <SignUpModal showModal={showModal} onConfirm={handleSignUp} onClose={() => setShowModal(false)} />

                    <div id="dynamic_ui" className="shiny-html-output shiny-bound-output">
                        <div id="prelogin">
                            <p className="logged_out_message2" style={{ marginRight: "20px" }}>
                                {translations.welcome2}
                            </p>
                        </div>
                        <br />
                        <form id="sign-sign_in_section" method="POST" onSubmit={handleSubmit}>
                            <div className="content-title">{translations.sign_in}</div>
                            <div className="form-group shiny-input-container">
                                <label htmlFor="sign-sign_in_user" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_in_user"
                                    type="text"
                                    name="email"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    placeholder={translations.username}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sign-sign_in_password" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_in_password"
                                    name="password"
                                    type="password"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    placeholder={translations.password}
                                />
                            </div>
                            <button className="btn action-button" type="submit">
                                {translations.sign_in}
                            </button>
                        </form>

                        <div className="vr-separator">
                            <div className="vr-line"></div>
                            <img src={songbirdIcon} alt="Songbird Icon" />
                            <div className="vr-line"></div>
                        </div>

                        <form id="sign_up_section" method="POST" ref={setForm} onSubmit={handleSignUpButtonClick}>
                            <div className="content-title-alt">{translations.sign_up}</div>
                            <div className="form-group">
                                <label htmlFor="sign-sign_up_user" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_up_user"
                                    type="text"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    name="username"
                                    placeholder={translations.username_sign_up}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sign-sign_in_email" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_in_email"
                                    name="email"
                                    type="text"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    placeholder={translations.email_sign_up}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sign-sign_up_password" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_up_password"
                                    type="password"
                                    name="password"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    placeholder={translations.password_sign_up}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sign-sign_up_verify_password" className="control-label shiny-label-null"></label>
                                <input
                                    id="sign-sign_up_verify_password"
                                    type="password"
                                    name="confirm_password"
                                    className="shiny-input-text form-control shiny-bound-input"
                                    placeholder={translations.verify_password_sign_up}
                                />
                            </div>
                            <button className="btn btn-default action-button btn_alt shiny-bound-input" type="submit">
                                {translations.sign_up}
                            </button>
                        </form>
                    </div>
                </div>
            )
            }
            <Footer />
        </div >
    );
};

export default Teacher;
