import AvailableTests from '../components/AvailableTests';
import AccountDetails from '../components/AccountDetails';
import YourItems from '../components/YourItems';
import SongbirdHome from '../components/SongbirdHome';
import MySingTraining from '../components/MySingTraining';
import YourProgress from './YourProgress';
import { AuthUser } from '@aws-amplify/auth'; // Importing AuthUser from AWS Amplify
import React, { useEffect, useState } from 'react';

const TabContent: React.FC<{
    session: AuthUser;
    activeTab: string;
    tests: any[],
    setTests: (tests: any[]) => void;
    hasTests: boolean;
    yourItems: any[];
    setActiveTab: (activeTab: string) => void;
    logoutUser: () => void;
    SingTrainingItems: any;
    gotSingTrainingItems: boolean;
    learningSong: boolean;
    setLearningSong: (isLearning: boolean) => void;
    setGotSingTrainingItems: (hasGot) => void;
}> = ({ session, activeTab, tests, setTests, hasTests, yourItems, 
    setActiveTab, logoutUser, SingTrainingItems, gotSingTrainingItems = false, 
    learningSong, setLearningSong, setGotSingTrainingItems }) => {

    const [chartData, setChartData] = useState<any>(null);
    const [hasChartData, setHasChartData] = useState(false);
    const [chartDataLoaded, setChartDataLoaded] = useState(false);

    const userId = parseInt(session?.userId);
    //const userId = 101; // For testing!

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchProgressData = async (userId: number) => {

        const url = `${API_BASE_URL}/get-trial-and-session-data`;
        const payload = { user_id: userId };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const result = await response.json();

            return result;
        } catch (error) {
            console.error('Error fetching progress data:', error);
            return null;
        }
    };

    useEffect(() => {

        const init = async () => {
            
            const result = await fetchProgressData(userId);

            if (result?.review_melodies?.length > 0 || result?.session_scores_rhythmic?.length > 0) {
                setChartData(result);
                setHasChartData(true);
            } else {
                setHasChartData(false);
            }
            setChartDataLoaded(true);
        };

        init();
    }, [userId]);

    return (
        <div className="tabbable">
            <div className="tab-content">
                {/* Tests Tab */}
                {activeTab === 'Tests' && (
                    <AvailableTests setTests={setTests} hasTests={hasTests} tests={tests} />
                )}

                {/* My Sing-Training Tab */}
                {activeTab === 'My Sing-Training' && (
                    <MySingTraining items={SingTrainingItems} gotItems={gotSingTrainingItems} setActiveTab={setActiveTab} setGotSingTrainingItems={setGotSingTrainingItems} />
                )}

                {/* Your Progress Tab */}
                {activeTab === 'Your Progress' && (
                    <div className="tab-pane active">
                        <YourProgress chartData={chartData} hasChartData={hasChartData} chartDataLoaded={chartDataLoaded} />
                    </div>
                )}

                {/* Your Items Tab */}
                {activeTab === 'Your Items' && (
                    <YourItems 
                    yourItems={yourItems} 
                    setActiveTab={setActiveTab}
                    learningSong={learningSong}
                    setLearningSong={setLearningSong} />
                )}

                {/* Account Details Tab */}
                {activeTab === 'SongBird' && (
                    <SongbirdHome setActiveTab={setActiveTab} />
                )}

                {activeTab === 'Account Details' && (
                    <AccountDetails logoutUser={logoutUser} />
                )}
            </div>
        </div>)

}

export default TabContent;