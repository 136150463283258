import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import enTranslations from '../translations/en.json';
import deTranslations from '../translations/de.json';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession, AuthSession, AuthUser } from '@aws-amplify/auth';
import { useNavigate, useLocation } from "react-router-dom";

import "../Cognito";

interface Translations {
  [key: string]: string;
}

// Context interface
interface AppContextType {
  translations: Translations;
  showModal: boolean;
  userId: string;
  sessionToken: any;
  isAuthenticated: boolean;
  isAdmin: boolean;
  switchLanguage: (lang: string) => void;
  setShowModal: (value: boolean) => void;
  session: AuthUser;
  currentLanguage: string
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [translations, setTranslations] = useState<Translations>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>('de');
  const [userId, setUserId] = useState<string>('');
  const [sessionToken, setSessionToken] = useState<AuthSession>();
  const [session, setSession] = useState<AuthUser>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const loadTranslations = (lang: string) => {
    setTranslations(lang === 'en' ? enTranslations : deTranslations);
  };

  const switchLanguage = (lang: string) => {
    setCurrentLanguage(lang);
    loadTranslations(lang);
  };

  const loadSessionData = async () => {
    try {
      const session = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const authSession :any = await fetchAuthSession();

      setUserId(userAttributes["custom:userId"] ?? '');
      setSessionToken(authSession);
      setSession(session);
      setIsAuthenticated(!!session);


      const groups = authSession?.tokens?.accessToken?.payload["cognito:groups"];

      if (!!session &&  location.pathname === '/teacher' ) {
      if (groups && Array.isArray(groups)) {
        setIsAdmin(groups.includes("admin"));
      } else {
        setIsAdmin(false);
        alert("UserNotAuthorized")

        navigate('/dashboard');

      }
    }
  
      if (!!session && location.pathname === '/') {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Error loading session data:", error);
      if (location.pathname !== '/' && location.pathname !== '/teacher') {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    loadSessionData();
  }, []);

  useEffect(() => {
    loadTranslations(currentLanguage);
  }, [currentLanguage]);

  return (
    <AppContext.Provider
      value={{
        translations,
        isAdmin,
        showModal,
        userId,
        sessionToken,
        isAuthenticated,
        session,
        switchLanguage,
        setShowModal,
        currentLanguage
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
